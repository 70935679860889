import React, { useState, useEffect } from 'react'
import projectsStyles from '../../style/projects.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'

import ApothekerVerband from '../../img/clients/apothekerverband.png'
import BForms from '../../img/clients/bforms.png'
import BigBrothersBigSisters from '../../img/clients/bigbrothersbigsisters.png'
import BitterlKoenig from '../../img/clients/bitterlkoenig.png'
import Emakina from '../../img/clients/emakina.svg'
import ErsteGroup from '../../img/clients/erstegroup.svg'
import Fensterblick from '../../img/clients/fensterblick.svg'
import FMP from '../../img/clients/fmp.png'
import Frameworks from '../../img/clients/frameworks.gif'
import Grossweber from '../../img/clients/grossweber.png'
import Hi1 from '../../img/clients/hi1.svg'
import I5invest from '../../img/clients/i5invest.png'
import InfinityMedia from '../../img/clients/infinitymedia.svg'
import LinBit from '../../img/clients/linbit.png'
import MonStyle from '../../img/clients/monstyle.svg'
import PlanNet from '../../img/clients/plannet.png'
import Post from '../../img/clients/post.png'
import PriceDesigns from '../../img/clients/pricedesigns.png'
import PsPdfKit from '../../img/clients/pspdfkit.svg'
import Publicis from '../../img/clients/publicis.png'
import Renox from '../../img/clients/renox.gif'
import Sclable from '../../img/clients/sclable.svg'
import Sozialbau from '../../img/clients/sozialbau-logo.svg'
import TabletSolutions from '../../img/clients/tabletsolutions.png'
import TracingThePast from '../../img/clients/tracingthepast.png'
import Tradex from '../../img/clients/tradex.svg'
import Validad from '../../img/clients/validad.svg'
import VHS from '../../img/clients/vhs.png'
import Virtue from '../../img/clients/virtue.svg'
import VSA from '../../img/clients/vsa.svg'
import VVO from '../../img/clients/vvo.svg'
import Wohn3 from '../../img/clients/wohn3.png'
import Xella from '../../img/clients/xella.svg'

const clients = [{
    name: 'apothekerverband',
    image: ApothekerVerband,
    url: 'https://www.apothekerverband.at/'
}, {
    name: 'bforms',
    image: BForms,
    url: 'https://www.bforms.it/',
}, {
    name: 'bigbrothersbigsisters',
    image: BigBrothersBigSisters,
    url: 'https://www.bigbrothers-bigsisters.at/',
}, {
    name: 'bitterlkoenig',
    image: BitterlKoenig,
    url: 'https://www.bklegal.at/',
}, {
    name: 'emakina',
    image: Emakina,
    url: 'https://www.emakina.at/',
}, {
    name: 'erstegroup',
    image: ErsteGroup,
    url: 'http://www.erstegroup.com/',
}, {
    name: 'fensterblick',
    image: Fensterblick,
    url: 'https://www.fensterblick.io/',
    invert: true,
}, {
    name: 'fmp',
    image: FMP,
    url: 'http://www.forummediaplanung.at/',
}, {
    name: 'frameworks',
    image: Frameworks,
    url: 'http://frameworks.co.at/',
}, {
    name: 'grossweber',
    image: Grossweber,
    url: 'https://grossweber.com/',
}, {
    name: 'hi1',
    image: Hi1,
    url: 'http://www.highone.at/',
}, {
    name: 'i5invest',
    image: I5invest,
    url: 'http://i5invest.com/',
}, {
    name: 'infinitymedia',
    image: InfinityMedia,
    url: 'https://www.infinitymedia.at/',
}, {
    name: 'linbit',
    image: LinBit,
    url: 'https://www.linbit.com/',
}, {
    name: 'monstyle',
    image: MonStyle,
    url: 'https://monstyle.io/',
}, {
    name: 'plannet',
    image: PlanNet,
    url: 'http://www.plan-net.at/',
}, {
    name: 'post',
    image: Post,
    url: 'https://www.post.at/',
}, {
    name: 'pricedesigns',
    image: PriceDesigns,
    url: 'http://www.pricedesigns.at/',
    invert: true,
}, {
    name: 'pspdfkit',
    image: PsPdfKit,
    url: 'https://pspdfkit.com/',
},
{
    name: 'publicis',
    image: Publicis,
    url: 'http://www.publicis.at/',
}, {
    name: 'renox',
    image: Renox,
    url: 'http://www.renox.at/',
}, {
    name: 'sclable',
    image: Sclable,
    url: 'https://sclable.com/',
}, {
    name: 'sozialbau',
    image: Sozialbau,
    url: 'http://www.sozialbau.at/',
}, {
    name: 'tabletsolutions',
    image: TabletSolutions,
    url: 'http://www.tabletsolutions.at/',
    invert: true,
}, {
    name: 'tracingthepast',
    image: TracingThePast,
    url: 'https://www.tracingthepast.org/',
    invert: true,
}, {
    name: 'tradex',
    image: Tradex,
    url: 'http://www.tradex-services.com/',
}, {
    name: 'validad',
    image: Validad,
    url: 'http://www.validad.com/',
}, {
    name: 'vhs',
    image: VHS,
    url: 'https://www.vhs.or.at/',
}, {
    name: 'virtue',
    image: Virtue,
    url: 'http://www.virtue.at/',
}, {
    name: 'vsa',
    image: VSA,
    url: 'http://www.songwriting.at/',
}, {
    name: 'VVO',
    image: VVO,
    url: 'https://www.vvo.at/',
}, {
    name: 'wohn3',
    image: Wohn3,
    url: 'http://wohndrei.at/',
}, {
    name: 'xella',
    image: Xella,
    url: 'https://www.xella.com/',
}]

const ClientList = () => {
    const [showMore, setShowMore] = useState(false)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(window.innerWidth)
    }, [width])

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [width])

    function toggleShowMore() {
        if (width > 768) {
            return { height: "auto" }
        }
        else {
            if (showMore) {
                return { height: "110rem" }
            } else {
                return { height: "20rem" }
            }
        }
    }

    function toggleBtnText() {
        if (showMore) {
            return <FormattedMessage id="Projects.show.less" />
        } else {
            return <FormattedMessage id="Projects.show.more" />
        }
    }


    return (
        <section className={projectsStyles.clientSection}>
            <div style={toggleShowMore()} className={projectsStyles.clientList}>
                {clients.sort((c1, c2) => c1.name.localeCompare(c2.name)).map((client, index) => (
                    <div key={`${index}-${client}`}>
                        <a style={client.invert ? { filter: "invert(1)" } : {}} href={client.url} target="_blank" rel="noreferrer">
                            <img className={projectsStyles.clientLogo} src={client.image} alt={client.name + "Logo"} />
                        </a>
                    </div>
                ))}

            </div>
            <button onClick={() => setShowMore(!showMore)} className={projectsStyles.showMoreClients}>{toggleBtnText()}</button>
        </section>
    )

}

export default ClientList
